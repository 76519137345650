import { useContext, useState, useEffect } from "react";
import { TcmsContext } from '../../../contexts/TcmsContext';
import TcmsImg from "../../../tcms/Tags/TcmsImg";
import Tcms from "../../../tcms/Tcms";
import { IoMdGift, IoMdCash, IoMdHeart } from 'react-icons/io';
import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";
import axios from "axios";

export default function Banner(props) {

  const BE = process.env.REACT_APP_MTG_BACKEND

  var welcomeMessage = false;
  if (props.welcome_message) {
    welcomeMessage = true;
  }
  const context = useContext(TcmsContext);
  const [headerClass, setHeaderClass] = useState('banner-normal');
  const [firstName, setFirstName] = useState(false);

  const getUser = () => {
    axios.get(BE + '/api/webshop/voucher').then(usr => {
      if (usr.data.firstname) {
        setFirstName(usr.data.firstname);
      }
    })
  }

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (context.customHeader) {
      setHeaderClass('banner-custom');
    }
  }, [context.customHeader]);

  return (
    <div className={`${headerClass}`}>
      <div className="banner-content">
        <div className="banner">
          <TcmsImg src="/media/images/default/banner.png" alt="Alt tekst top menu" className="wavy-image"/>
          <div className="overlay">
            <div className="container__large">
              <div className="wrapper">
                {welcomeMessage && 
                  <div className="welcome-message">
                    <h1 className="beste"><span><Tcms>banner_beste</Tcms></span> <span>{firstName}</span> <span><Tcms>banner_beste_comma</Tcms></span></h1>
                    <TcmsWYSIWYG>Banner_intro</TcmsWYSIWYG>
                  </div>
                }
                <div className={"buttons " + Tcms('toggle_banner_buttons')}>
                  <button className="button">
                    <IoMdGift size={24} style={{ marginRight: '10px' }} /> <Tcms>give_gift_label</Tcms>
                  </button>
                  <button className="button">
                    <IoMdCash size={24} style={{ marginRight: '10px' }} /> <Tcms>give_points_label</Tcms>
                  </button>
                  <button className="button">
                    <IoMdHeart size={24} style={{ marginRight: '10px' }} /> <Tcms>donate_points_label</Tcms>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {welcomeMessage && 
        <div className="welcome-message res-welcome-message">
          <h1 className="beste"><span><Tcms>banner_beste</Tcms></span> <span>{firstName}</span></h1>
          <TcmsWYSIWYG>Banner_intro</TcmsWYSIWYG>
        </div>
      }
      {context.editMode &&
        <div className="banner_edit tcms-edit-wrapper">
          <div className="banner_edit_buttons">
            <div className="label">Toggle banner buttons</div>
            <div className="value"><Tcms>toggle_banner_buttons</Tcms></div>
          </div>
          <div className='banner_edit_image'>
            <div className="label">Banner image</div>
            <div className="value"><TcmsImg src="/media/images/default/banner.png" alt="Alt tekst top menu" className="wavy-image"/></div>
          </div>
        </div>
      }
    </div>
  );
}
