import React, { useContext, useEffect } from 'react';
import '../../../sass/_footer.scss';
import { FaWhatsapp, FaPhone, FaEnvelope } from 'react-icons/fa';
import { IoMdPlayCircle } from 'react-icons/io';
import { MdQuestionAnswer } from 'react-icons/md';
import { TcmsContext } from '../../../contexts/TcmsContext';
import Tcms from '../../../tcms/Tcms';
import TcmsImg from '../../../tcms/Tags/TcmsImg';
import Thuiswinkel from '../menu/Thuiswinkel';


const darkenColor = (color, percent) => {
  let R, G, B;

  if (color.startsWith('rgb')) {
    // Extract the RGB values from the color string
    const rgbValues = color.match(/\d+/g);
    R = parseInt(rgbValues[0]);
    G = parseInt(rgbValues[1]);
    B = parseInt(rgbValues[2]);
  } else {
    // Convert hex to RGB
    const num = parseInt(color.replace("#", ""), 16);
    R = (num >> 16);
    G = (num >> 8 & 0x00FF);
    B = (num & 0x0000FF);
  }

  const amt = Math.round(2.55 * percent);
  R = R + amt;
  G = G + amt;
  B = B + amt;

  R = R < 255 ? (R < 0 ? 0 : R) : 255;
  G = G < 255 ? (G < 0 ? 0 : G) : 255;
  B = B < 255 ? (B < 0 ? 0 : B) : 255;

  return `rgb(${R}, ${G}, ${B})`;
}

const Footer = () => {
  useEffect(() => {
    // rootStyles is not fetched in time sometimes, so we need timeout for setting the background color 
    setTimeout(() => {
      const rootStyles = getComputedStyle(document.documentElement);
      const mtg1Color = rootStyles.getPropertyValue('--mtg-1').trim();
      const darkerColor = darkenColor(mtg1Color, -30); // Darken by 30%
      document.querySelector('.bottom-footer').style.backgroundColor = darkerColor;
    }, 1000);

  }, []);
  const context = useContext(TcmsContext);
  return (
    <div className="footer-container">
      <svg className="footer-top-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="var(--mtg-1)" fillOpacity="1" d="M0,160L120,176C240,192,480,224,720,229.3C960,235,1200,213,1320,202.7L1440,192L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
      </svg>
      <div className="main-footer-container">
        <div className='container__large'>
          <div className="footer">
            <div className="footer-left">
              <h2><Tcms>quick_links</Tcms></h2>
              <div className="instruction">
                <IoMdPlayCircle className="icon" />
                <a href={Tcms('instruction_video_link')}><span><Tcms>instruction_video</Tcms></span></a>
                {context.editMode &&
                  <>
                    <span>Link:</span> <Tcms>instruction_video_link</Tcms>
                  </>
                }
              </div>
              <div className="faq">
                <MdQuestionAnswer className="icon" />
                <a href={Tcms('footer_faq_link')}><span><Tcms>footer_faq</Tcms></span></a>
                {context.editMode &&
                  <>
                    <span>Link:</span> <Tcms>footer_faq_link</Tcms>
                  </>
                }
              </div>
              <Thuiswinkel />
            </div>
            <div className="footer-right">
              <h2><Tcms>footer_help</Tcms></h2>
              <div className="contact-info">
                <div className="contact-item">
                  <FaWhatsapp className="icon" />
                  <a target='_blank' href={Tcms('footer_wa_link')}><span><Tcms>footer_wa</Tcms></span></a>
                  {context.editMode &&
                  <>
                    <span>Link:</span> <Tcms>footer_wa_link</Tcms>
                  </>
                }
                </div>
                <div className="contact-item">
                  <FaPhone className="icon" />
                  <a href={"tel:"+Tcms('footer_ph')}><span><Tcms>footer_ph</Tcms></span></a>
                </div>
                <div className="contact-item">
                  <FaEnvelope className="icon" />
                  <a href={Tcms('footer_em_link')}><span><Tcms>footer_em</Tcms></span></a>
                  {context.editMode &&
                  <>
                    <span>Link:</span> <Tcms>footer_em_link</Tcms>
                  </>
                }
                </div>
              </div>
            </div>
            <TcmsImg src="https://png.pngtree.com/png-vector/20230918/ourmid/pngtree-man-in-shirt-smiles-and-gives-thumbs-up-to-show-approval-png-image_10094392.png" alt="Person" className="person-image" />
          </div>
        </div>
        <div className='bottom-footer'>
          <div className='container__large'>
            <div className='wrapper'>
              <a href={'/'+context.webshopSlug+'/'+Tcms('privacy_link')}><Tcms>privacy_label</Tcms></a> <span><Tcms>copyright</Tcms> {new Date().getFullYear()}</span>
            </div>
            {context.editMode && 
              <div className="tcms-edit-wrapper">
                <div className="privacy_link">
                  <div className="label">
                    Privacy link:
                  </div>
                  <div className="value">
                    <Tcms>privacy_link</Tcms>
                  </div>
                </div>
                <div className="privacy_label">
                <div className="label">
                    Privacy label:
                  </div>
                  <div className="value">
                    <Tcms>privacy_label</Tcms>
                  </div>
                </div>
              </div>
            }
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Footer;
