import React, { useContext, useState, useEffect } from 'react'
import Menu from '../../../components/webshop/menu/Menu'
import Filter from '../../../components/webshop/filter/Filter'
import Banner from '../../../components/webshop/banner/Banner'
import Sticky from 'react-stickynode';
import Sort from '../../../components/webshop/sort/Sort'
import { TcmsContext } from '../../../contexts/TcmsContext';
import { Sidebar } from 'primereact/sidebar';
import WebshopProducts from '../../../components/webshop/WebshopProducts';
import RibbonFilter from "../../../components/webshop/filter/RibbonFilter";
import Background from '../../../components/webshop/background/Background'
import BuyCredit from "../../../components/webshop/BuyCredit";
import Tcms from '../../../tcms/Tcms'
import { FilterContext } from '../../../contexts/FilterContext';
import Footer from '../../../components/webshop/footer/Footer';
import PurchaseIndicator from '../../../components/webshop/PurchaseIndicator';
import TcmsWYSIWYG from '../../../tcms/Tags/TcmsWYSIWYG';

let timeoutRef = null
function Webshop({ rootComponent }) {
  let filterContext = useContext(FilterContext)
  const context = useContext(TcmsContext)
  const [isStickyEnabled, setStickyEnabled] = useState(true);
  const [sidebarFilterVisible, setSidebarFilterVisible] = useState()
  const [search, setSearch] = useState('')

  const toggleSideBarFilter = () => {
    setSidebarFilterVisible(!sidebarFilterVisible);
  }

  const filter = rootComponent.props.match.params.filter;

  useEffect(() => {

    if (!filterContext.setActiveSubCats.length > 0) {
      filterContext.setActiveHoofdCatId(4);
    }

    if (!filterContext.activeHoofdCatId) {
      filterContext.setActiveHoofdCatId(4);
    }

    if (filter === "goede-doelen") {
      filterContext.setDefaultHoofdCatId(16);
    }

    if (filter === "mode-verzorging") {
      filterContext.setDefaultHoofdCatId(12);
      filterContext.setActiveHoofdCatId(17);
      filterContext.setActiveSubCats([12]);
    }

    if (filter === "electronica-gadgets") {
      filterContext.setDefaultHoofdCatId(39);
      filterContext.setActiveHoofdCatId(17);
      filterContext.setActiveSubCats([39]);
    }

    if (filter === "koken-tafelen") {
      filterContext.setDefaultHoofdCatId(9);
      filterContext.setActiveHoofdCatId(17);
      filterContext.setActiveSubCats([9]);
    }

    if (filter === "exclusief") {
      filterContext.setDefaultHoofdCatId(17);
    }

    if (filter === "alle-geschenken") {
      filterContext.setDefaultHoofdCatId(4);
    }

    if (filter === "duurzaam") {
      filterContext.setCustomRibbon(4);
    }

    if (filter === "top-10") {
      filterContext.setCustomRibbon(38);
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const threshold = document.body.scrollHeight - 500; // Disable sticky when 500px from the bottom

      if (scrollPosition >= threshold && isStickyEnabled) {
        setStickyEnabled(false);
      } else if (scrollPosition < threshold && !isStickyEnabled) {
        setStickyEnabled(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isStickyEnabled]);

  return (
    <>
      <div className='page-wrapper'>
        <PurchaseIndicator/>
        <Background />
        <div className="mtg-webshop main-view">
          {/*This is the filter sidebar on mobile*/}
          {context.mobile &&
            <Sidebar
              style={{ width: '18rem', top: '0px' }}
              position="left"
              modal={true}
              visible={sidebarFilterVisible}
              onHide={() => setSidebarFilterVisible(false)}>
              <Filter toggleSideBarFilter={toggleSideBarFilter} />
            </Sidebar>}

          <Menu />

          <Banner />

          {/* here */}
          <div className="webshop-content default">
            <div className="custom-webshop-container">
              {context.editMode && filter &&
                <Tcms>{filter.replace('-', '_')}</Tcms>
              }
              <div className="search search-xs">
                {filter &&
                  <div className="category_title"><h2><Tcms>{filter.replace('-', '_')}</Tcms></h2></div>
                }
                <div className='search-wrapper'>
                  <input className="form-control"
                    placeholder="Waar ben je naar opzoek?"
                    value={search}
                    onChange={e => {
                      setSearch(e.target.value)
                      // if(timeoutRef){
                      //   clearTimeout(timeoutRef)
                      // }
                      // // Have to time it before we ass it to the filter.. otherwise to slow
                      // timeoutRef = setTimeout(()=>{
                      //   filterContext.setSearch(e.target.value)
                      // },250)

                    }} />
                  <span className="icon" onClick={(e) => {
                    if (timeoutRef) {
                      clearTimeout(timeoutRef)
                    }
                    timeoutRef = setTimeout(() => {
                      filterContext.setSearch(search)
                    }, 250)
                  }}>
                    <i className="pi pi-search" />
                  </span>
                </div>
              </div>

              {/* <Breadcrumbs/> */}

              <div className="p-d-sm-none xs-sticky">
                <a className="p-button p-togglebutton p-component p-button-filter" href="/" onClick={(e) => {
                  e.preventDefault()
                  toggleSideBarFilter()
                }}>

                  Filter
                </a>
              </div>

              <div className="p-grid">

                {/*The filter on desktop*/}
                <div className="p-lg-3 p-md-5 p-sm-6 p-d-none p-d-sm-block col-filters">
                  <Sticky enabled={isStickyEnabled} top={75}>
                    <div className="filter">
                      <Filter />
                      {/* Ribbons  */}
                      <div className="product-sort-container">
                        <h3><Tcms>Productsoort:</Tcms></h3>
                        {context.meta.ribbons !== undefined && context.meta.ribbons.length !== 0 && <RibbonFilter />}
                      </div>

                      {context.meta.ribbons !== undefined && context.meta.ribbons.length !== 0 && context.editMode &&
                        <>
                          {context.meta.ribbons.map(el => {
                            return <div className='tcms-edit-wrapper'>
                              <div className='ribbon-name'>
                                <div className="label">
                                  Ribbonname:
                                </div>
                                <div className='value'>
                                  <Tcms>{el.name}</Tcms>
                                </div>
                              </div>
                              <div className="ribbon-tooltip">
                                <div className='label'>
                                  Ribbonlabel:
                                </div>
                                <div className='value'>
                                  <Tcms>{'ribbon_tooltip_'+el.id}</Tcms>
                                </div>
                              </div>
                            </div>
                          })}
                        </>
                      }
                    </div>
                  </Sticky>
                </div>

                <div className="p-col-12 p-lg-9 p-md-7 p-sm-6 col-products">


                  {/* Sort  */}
                  <div className='hidden-xs'>
                    <Sort />
                  </div>
                  {/* Products */}
                  <WebshopProducts />

                  <BuyCredit />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )

}


export default Webshop
