import React, { useContext, useEffect, useRef, useState } from 'react';
import Menu from '../../../components/webshop/menu/Menu';
import { TcmsContext } from '../../../contexts/TcmsContext';
import Background from '../../../components/webshop/background/Background';
import TcmsImg from '../../../tcms/Tags/TcmsImg';
import TcmsButton from '../../../tcms/Tags/TcmsButton';
import Tcms from '../../../tcms/Tcms';
import Banner from '../../../components/webshop/banner/Banner';
import { FaCoins, FaGift } from 'react-icons/fa';
import { BsCartCheckFill } from 'react-icons/bs';
import DialogHome from '../../../components/webshop/DialogHome';
import Footer from '../../../components/webshop/footer/Footer';
import { Accordion, AccordionTab } from 'primereact/accordion';
import TcmsWYSIWYG from '../../../tcms/Tags/TcmsWYSIWYG';
import { Badge } from 'primereact/badge';
import TcmsDiv from '../../../tcms/Tags/TcmsDiv';

const Home = () => {
  const context = useContext(TcmsContext);
  const refDialogHome = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(4);
  const totalItems = 6;

  const tabs = ['how_header_1','how_header_2','how_header_3'];
  const [accordionActiveIndex, setAccordionActiveIndex] = useState(false);

  const checkDisabled = (value) => {
    return !!value;
  }

  useEffect(() => {
    if (window.innerWidth < 768) {
      setItemsToShow(1);
    }
    refDialogHome.current.open();
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? totalItems - itemsToShow : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === totalItems - itemsToShow ? 0 : prevIndex + 1));
  };

  return (
    <>
      <Menu />
      <div className='page-wrapper page-home'>
        <Background />
        <Banner welcome_message={true} />

        <section className='featured-categories'>
          <div className='container__large'>
            {context.editMode && (
              <div className="tcms-edit-wrapper">
                <div className='home_toggle'>
                  <div className="label">Dialog toggle</div>
                  <div className="value"><Tcms>dialog_home_toggle</Tcms></div>
                </div>
              </div>
            )}
            <div className='p-grid'>

              <div className="p-sm-6 p-md-8 p-col p-col-12">

                <div className='p-grid mb-30'>

                  <div className='p-sm-12 p-md-5 p-col p-col-12'>
                    <div className='cat'>
                      {context.editMode &&
                        <div className="tcms-edit-wrapper">
                          <div>
                            <div className="label">
                              Description
                            </div>
                            <div className="value">
                              <TcmsWYSIWYG>home_cat_desc_1</TcmsWYSIWYG>
                            </div>
                          </div>
                          <div>
                            <div className="label">
                              Link:
                            </div> 
                            <div className="value">
                              <Tcms>home_cat_link_1</Tcms>
                            </div>
                          </div>
                          <div className='tcms-image-edit'>
                            <div className="label">
                              Image
                            </div>
                            <div className="value">
                              <TcmsImg src="home_cat_image_1" alt="home_cat_alt_1"/>
                            </div>
                          </div>
                        </div>
                      }
                      <a href={"/"+context.webshopSlug+"/"+Tcms('home_cat_link_1')}>
                        <div className='image'>
                          <TcmsImg src="home_cat_image_1" alt="home_cat_alt_1"/>
                          <div className='content'>
                            <h2><Tcms>home_cat_label_1</Tcms></h2>
                            <div className="home_cat_desc"><TcmsWYSIWYG>home_cat_desc_1</TcmsWYSIWYG></div>
                            <div className='home_cat_link'><Tcms>shop_now</Tcms><span className="pi pi-chevron-right"></span></div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div className='p-sm-12 p-md-7 p-col  p-col-12'>
                    <div className='cat'>
                      {context.editMode &&
                        <div className="tcms-edit-wrapper">
                          <div>
                            <div className="label">
                              Description
                            </div>
                            <div className="value">
                              <TcmsWYSIWYG>home_cat_desc_2</TcmsWYSIWYG>
                            </div>
                          </div>
                          <div>
                            <div className="label">
                              Link:
                            </div> 
                            <div className="value">
                              <Tcms>home_cat_link_2</Tcms>
                            </div>
                          </div>
                          <div className='tcms-image-edit'>
                            <div className="label">
                              Image
                            </div>
                            <div className="value">
                              <TcmsImg src="home_cat_image_2" alt="home_cat_alt_2"/>
                            </div>
                          </div>
                        </div>
                      }
                      <a href={"/"+context.webshopSlug+"/"+Tcms('home_cat_link_2')}>
                        <div className='image'>
                          <TcmsImg src="home_cat_image_2" alt="home_cat_alt_2"/>
                          <div className='content'>
                            <h2><Tcms>home_cat_label_2</Tcms></h2>
                            <div className="home_cat_desc"><TcmsWYSIWYG>home_cat_desc_2</TcmsWYSIWYG></div>
                            <div className='home_cat_link'><Tcms>shop_now</Tcms><span className="pi pi-chevron-right"></span></div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>

                </div>

                <div className='p-grid'>

                  <div className='p-sm-12 p-md-7 p-col  p-col-12'>
                    <div className='cat'>
                      {context.editMode &&
                        <div className="tcms-edit-wrapper">
                          <div>
                            <div className="label">
                              Description
                            </div>
                            <div className="value">
                              <TcmsWYSIWYG>home_cat_desc_3</TcmsWYSIWYG>
                            </div>
                          </div>
                          <div>
                            <div className="label">
                              Link:
                            </div> 
                            <div className="value">
                              <Tcms>home_cat_link_3</Tcms>
                            </div>
                          </div>
                          <div className='tcms-image-edit'>
                            <div className="label">
                              Image
                            </div>
                            <div className="value">
                              <TcmsImg src="home_cat_image_3" alt="home_cat_alt_3"/>
                            </div>
                          </div>
                        </div>
                      }
                      <a href={"/"+context.webshopSlug+"/"+Tcms('home_cat_link_3')}>
                        <div className='image'>
                          <TcmsImg src="home_cat_image_3" alt="home_cat_alt_3"/>
                          <div className='content'>
                            <h2><Tcms>home_cat_label_3</Tcms></h2>
                            <div className="home_cat_desc"><TcmsWYSIWYG>home_cat_desc_3</TcmsWYSIWYG></div>
                            <div className='home_cat_link'><Tcms>shop_now</Tcms><span className="pi pi-chevron-right"></span></div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div className='p-sm-12 p-md-5 p-col p-col-12'>
                    <div className='cat'>
                      {context.editMode &&
                        <div className="tcms-edit-wrapper">
                          <div>
                            <div className="label">
                              Description
                            </div>
                            <div className="value">
                              <TcmsWYSIWYG>home_cat_desc_4</TcmsWYSIWYG>
                            </div>
                          </div>
                          <div>
                            <div className="label">
                              Link:
                            </div> 
                            <div className="value">
                              <Tcms>home_cat_link_4</Tcms>
                            </div>
                          </div>
                          <div className='tcms-image-edit'>
                            <div className="label">
                              Image
                            </div>
                            <div className="value">
                              <TcmsImg src="home_cat_image_4" alt="home_cat_alt_4"/>
                            </div>
                          </div>
                        </div>
                      }
                      <a href={"/"+context.webshopSlug+"/"+Tcms('home_cat_link_4')}>
                        <div className='image'>
                          <TcmsImg src="home_cat_image_4" alt="home_cat_alt_4"/>
                          <div className='content'>
                            <h2><Tcms>home_cat_label_4</Tcms></h2>
                            <div className="home_cat_desc"><TcmsWYSIWYG>home_cat_desc_4</TcmsWYSIWYG></div>
                            <div className='home_cat_link'><Tcms>shop_now</Tcms><span className="pi pi-chevron-right"></span></div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>

                </div>

              </div>
              <div className="p-sm-6 p-md-4 p-col p-col-12">
                <div className='cat cat-r'>
                  {context.editMode &&
                    <div className="tcms-edit-wrapper">
                      <div>
                        <div className="label">
                          Description
                        </div>
                        <div className="value">
                          <TcmsWYSIWYG>home_cat_desc_5</TcmsWYSIWYG>
                        </div>
                      </div>
                      <div>
                        <div className="label">
                          Link:
                        </div> 
                        <div className="value">
                          <Tcms>home_cat_link_5</Tcms>
                        </div>
                      </div>
                      <div className='tcms-image-edit'>
                        <div className="label">
                          Image
                        </div>
                        <div className="value">
                          <TcmsImg src="home_cat_image_5" alt="home_cat_alt_5"/>
                        </div>
                      </div>
                    </div>
                  }
                  <a href={"/"+context.webshopSlug+"/"+Tcms('home_cat_link_5')}>
                    <div className='image image-h'>
                      <TcmsImg src="home_cat_image_5" alt="home_cat_alt_5"/>
                      <div className='content'>
                        <h2><Tcms>home_cat_label_5</Tcms></h2>
                        <div className="home_cat_desc"><TcmsWYSIWYG>home_cat_desc_5</TcmsWYSIWYG></div>
                        <div className='home_cat_link'><Tcms>shop_now</Tcms><span className="pi pi-chevron-right"></span></div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className='wave-divider'>
          <svg width="100%" height="100%" className='wave' viewBox="0 0 1440 190" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M 0,400 L 0,0 C 257.5,62 515,124 755,124 C 995,124 1217.5,62 1440,0 L 1440,200 L 0,200 Z"
              stroke="none"
              strokeWidth="0"
              fillOpacity="1"
            ></path>
          </svg>
        </div>

        <section className='featured-products'>
          <div className='title'>
            <h2><Tcms>featured_products_title</Tcms></h2>
          </div>
          <div className='container__large'>
            <button className='prev' onClick={handlePrev}>❮</button>
            <div className='slider-container'>
              <div className='slider' style={{ transform: `translateX(-${currentIndex * (100 / itemsToShow)}%)` }}>
                <div className='p-sm-6 p-col-12 p-md-3 p-col'>
                  <div className='product'>
                    <div className='image'>
                      <TcmsImg src="home_product_image_1"  alt="home_product_alt_1"/>
                      <div className='cat_label'>
                        <Tcms>home_product_cat_1</Tcms>
                      </div>
                    </div>
                    <div className="content">
                      <h3><Tcms>home_product_label_1</Tcms></h3>
                      <p><Tcms>home_product_description_1</Tcms></p>
                      <p><a href={"/"+context.webshopSlug+"/"+Tcms('home_product_link_1')}><TcmsButton alt="Lees verder" label="Lees verder"></TcmsButton></a></p>
                      {context.editMode &&
                        <>
                          <span>Link:</span> <Tcms>home_product_link_1</Tcms>
                        </>
                      }
                    </div>
                  </div>
                </div>

                <div className='p-sm-6 p-col-12 p-md-3 p-col'>
                  <div className='product'>
                    <div className='image'>
                      <TcmsImg src="home_product_image_2"  alt="home_product_alt_2"/>
                      <div className='cat_label'>
                        <Tcms>home_product_cat_2</Tcms>
                      </div>
                    </div>
                    <div className="content">
                      <h3><Tcms>home_product_label_2</Tcms></h3>
                      <p><Tcms>home_product_description_2</Tcms></p>
                      <p><a href={"/"+context.webshopSlug+"/"+Tcms('home_product_link_2')}><TcmsButton alt="Lees verder" label="Lees verder"></TcmsButton></a></p>
                      {context.editMode &&
                        <>
                          <span>Link:</span> <Tcms>home_product_link_2</Tcms>
                        </>
                      }
                    </div>
                  </div>
                </div>

                <div className='p-sm-6 p-col-12 p-md-3 p-col'>
                  <div className='product'>
                    <div className='image'>
                      <TcmsImg src="home_product_image_3"  alt="home_product_alt_3"/>
                      <div className='cat_label'>
                        <Tcms>home_product_cat_3</Tcms>
                      </div>
                    </div>
                    <div className="content">
                      <h3><Tcms>home_product_label_3</Tcms></h3>
                      <p><Tcms>home_product_description_3</Tcms></p>
                      <p><a href={"/"+context.webshopSlug+"/"+Tcms('home_product_link_3')}><TcmsButton alt="Lees verder" label="Lees verder"></TcmsButton></a></p>
                      {context.editMode &&
                        <>
                          <span>Link:</span> <Tcms>home_product_link_3</Tcms>
                        </>
                      }
                    </div>
                  </div>
                </div>

                <div className='p-sm-6 p-col-12 p-md-3 p-col'>
                  <div className='product'>
                    <div className='image'>
                      <TcmsImg src="home_product_image_4" alt="home_product_alt_4" />
                      <div className='cat_label'>
                        <Tcms>home_product_cat_4</Tcms>
                      </div>
                    </div>
                    <div className="content">
                      <h3><Tcms>home_product_label_4</Tcms></h3>
                      <p><Tcms>home_product_description_4</Tcms></p>
                      <p><a href={"/"+context.webshopSlug+"/"+Tcms('home_product_link_4')}><TcmsButton alt="Lees verder" label="Lees verder"></TcmsButton></a></p>
                      {context.editMode &&
                        <>
                          <span>Link:</span> <Tcms>home_product_link_4</Tcms>
                        </>
                      }
                    </div>
                  </div>
                </div>

                {/* Additional Items */}
                <div className='p-sm-6 p-col-12 p-md-3 p-col'>
                  <div className='product'>
                    <div className='image'>
                      <TcmsImg src="home_product_image_5" alt="home_product_alt_5"/>
                      <div className='cat_label'>
                        <Tcms>home_product_cat_5</Tcms>
                      </div>
                    </div>
                    <div className="content">
                      <h3><Tcms>home_product_label_5</Tcms></h3>
                      <p><Tcms>home_product_description_5</Tcms></p>
                      <p><a href={"/"+context.webshopSlug+"/"+Tcms('home_product_link_5')}><TcmsButton alt="Lees verder" label="Lees verder"></TcmsButton></a></p>
                      {context.editMode &&
                        <>
                          <span>Link:</span> <Tcms>home_product_link_5</Tcms>
                        </>
                      }
                    </div>
                  </div>
                </div>

                <div className='p-sm-6 p-col-12 p-md-3 p-col'>
                  <div className='product'>
                    <div className='image'>
                      <TcmsImg src="home_product_image_6" alt="home_product_alt_6" />
                      <div className='cat_label'>
                        <Tcms>home_product_cat_6</Tcms>
                      </div>
                    </div>
                    <div className="content">
                      <h3><Tcms>home_product_label_6</Tcms></h3>
                      <p><Tcms>home_product_description_6</Tcms></p>
                      <p><a href={"/"+context.webshopSlug+"/"+Tcms('home_product_link_6')}><TcmsButton alt="Lees verder" label="Lees verder"></TcmsButton></a></p>
                      {context.editMode &&
                        <>
                          <span>Link:</span> <Tcms>home_product_link_6</Tcms>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className='next' onClick={handleNext}>❯</button>
          </div>
        </section>

        <section className='how'>
        
          <div className='bg-wrapper'  >
            <div className='bg-image' style={{
            backgroundImage: `url('${Tcms('home_how_bg_image')}')`,
          }} >
            </div>
          {context.editMode &&
          <TcmsDiv style={{
            backgroundImage: `url('home_how_bg_image')`,
          }} className="tcms-bg-image-picker"/>}
            <div className='container__large'>
              <div className='bg'>
                <div className='title'>
                  <h2><TcmsWYSIWYG>home_how_title</TcmsWYSIWYG></h2>
                </div>
                <div className='intro'>
                  <p><TcmsWYSIWYG>home_how_intro</TcmsWYSIWYG></p>
                </div>
                <div className='p-grid'>
                  <div className='p-col-12'>
                    <Accordion activeIndex={accordionActiveIndex} onTabChange={(e) => setAccordionActiveIndex(e.index)}>
                      {tabs.map((tab, i) => {
                        if (checkDisabled(Tcms(tab))) {
                          return (
                            <AccordionTab key={i} header={
                                <span className="w-full">
                                  <Badge className='tab-header-badge' value={i+1}/>
                                  <span className='tab-header-text'><Tcms>{tab}</Tcms></span>
                                </span>
                              }>
                              <TcmsWYSIWYG>{`how_tab_content_${i + 1}`}</TcmsWYSIWYG>
                            </AccordionTab>
                          );
                        }
                      })}
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <DialogHome
          toggle={Tcms('dialog_home_toggle')}
          className='home-dialog'
          ref={refDialogHome}
        />
      </div>
      <Footer />
    </>
  );
};

export default Home;