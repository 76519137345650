import React from 'react';
import TcmsImg from "../../../tcms/Tags/TcmsImg";

class Thuiswinkel extends React.Component {
    render() {
        return (
            <div className="thuiswinkel">
                <a href="https://www.thuiswinkel.org/leden/nationale-waarderingsbon/certificaat" target='_blank'>
                    <TcmsImg
                        alt="thuiswinkel"
                        src={`/media/icons/thuiswinkel-logo.png`}/>
                </a>
            </div>
        );
    }
}

export default Thuiswinkel;