import React, { useContext, useEffect, useState, useRef } from 'react'

import axios from 'axios'
import { Dropdown } from 'primereact/dropdown'
import Tcms from '../../../tcms/Tcms'
import TcmsSpan from '../../../tcms/Tags/TcmsSpan'
import TcmsImg from '../../../tcms/Tags/TcmsImg'
import TcmsWYSIWYG from '../../../tcms/Tags/TcmsWYSIWYG'
import { InputNumber } from 'primereact/inputnumber'
import { TcmsContext } from '../../../contexts/TcmsContext'
import { Button } from 'primereact/button'
import classNames from 'classnames'
import { FilterContext } from "../../../contexts/FilterContext";
import Product from '../product/Product';
import getRibbons from "../../../services/getRibbons";
import SidebarCart from "../menu/SidebarCart";
import slug from "../../../services/slug";
import { useHistory } from "react-router-dom";
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import PurchaseIndicator from '../PurchaseIndicator'

const BE = process.env.REACT_APP_MTG_BACKEND
const imgPath = BE + '/storage/img/odoo-producten/555x555/'
const srcErr = '/media/images/placeholders/555x555.png'

const Pdp = ({ product_id, replaceWithPdp, backClicked }) => {
  const history = useHistory()
  const context = useContext(TcmsContext)
  const filterContext = useContext(FilterContext)

  const balance = context.getBalance();

  const [variantSelected, setVariantSelected] = useState(false)
  const [loading, setLoading] = useState(true)
  const [product, setProduct] = useState(true)
  const [new_product_id, setNewProductId] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);

  const ribbonIcons = context.ribbonIcons;

  // Count items
  const [no, setNo] = useState()
  const [orderCount, setOrderCount] = useState()

  const { categories } = context.products
  const refSidebarCart = useRef();

  const [products, setProducts] = useState([]);
  const [hoofdCats, setHoofdCats] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const getRibbonId = (ribbon) => {
    let result = context.meta.ribbons.filter(obj => {
      if (obj.name === ribbon)  {
        return obj.id;
      }
    });
    return result[0].id
  };

  useEffect(() => {
    setProducts(context.products.products);
    setHoofdCats(context.products.categories.filter((el) => el.parent_id === 4));

    // Log products to console
    // console.log('Individual product:', product);
    // console.log('Products:', context.products.products);
    // console.log('Categories:', context.products.categories);
  }, [context.products.products, context.products.categories]);

  useEffect(() => {
    if (product && products.length > 0) {
      const related = getRelatedProducts(product, products);
      setRelatedProducts(related);
      // console.log('related', related);

    }
    if (product && window.innerWidth < 768) {
      setIsOpen(true);
    }
  }, [product, products]);

  useEffect(() => {

    /**
     * The timeout here is for UX (debuggin purposes)
     */
    axios.get(BE + '/api/webshop/product?id=' + product_id).then(res => {
      // Specs to object
      res.data.specs = JSON.parse(res.data.specs || '[]')
      setProduct(res.data)
      setLoading(false)
    })
  },
    // You need context.languageCode for the lang switch
    // eslint-disable-next-line
    [context.languageCode, product_id, categories]
  )

  useEffect(() => {

    if (!product) return;

    // And set the max of the +- thing
    let max = Math.floor(context.getBalance() / product.price_in_eco)
    setOrderCount(1)
    if (max > 0) {
      setNo({
        min: 1,
        max: max,
        count: 1
      })
    } else {
      setNo({
        min: 0,
        max: 0,
        count: 0
      })
    }
  }, [product, context])

  const addToCartClicked = (id, count) => {
    axios.get(process.env.REACT_APP_MTG_BACKEND + '/api/webshop/add-to-cart', {
      params: {
        id, count
      }
    }).then(res => {
      console.log(context);
      // Have to update the jwt_token and the cart
      context.setCart(res.data.cart)
      context.renewJwt(res.data.jwt_token)

      const purchaseIndicator = document.getElementById('purchase-indicator');
      purchaseIndicator.classList.add('show');

      setTimeout(() => {
        purchaseIndicator.classList.remove('show');
      }, 1750);

      setTimeout(() => {
        refSidebarCart.current.open()
      }, 1750);
    })
  }

  const capitalizeFirstLetter = (string) => {
      string = string.toLowerCase();
      return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getRelatedProducts = (product, allProducts) => {
    const related = allProducts.filter(p => p.category_id === product.category_id && p.id !== product.id);
    return getRandomProducts(related, 4);
  };

  const getRandomProducts = (products, count) => {
    let shuffled = products.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const heartClicked = () => {
    // Add or remove
    const { uid } = context.jwt.usr
    axios.get(`${BE}/api/webshop/favorites`, { params: { uid, id: product.id } }).then(res => {
      context.setFavorites(res.data ? res.data.split(',').map(el => parseInt(el)) : '')
    })
  }

  const availability = () => {
    if (!product.stock.can_buy) {
      return { className: 'unavailable', label: 'MOMENTEEL NIET VOORRADIG' }
    }
    return { className: 'avail', label: 'OP VOORRAAD' }
  }

  const limitededition = () => {
    if (product.stock.limited_edition) {
      return { className: 'limited', label: 'LIMITED EDITION' }
    }
    return { className: '', label: '' }
  }

  const onSelectChange = (new_product_id) => {
    if (replaceWithPdp) {
      replaceWithPdp(new_product_id)
    } else {
      replaceProduct(new_product_id)
      // Need the name of the product for the slug
      // const prod = context.products.products.find(e => e.id === product_id)
      // history.push(`/${context.webshopSlug}/product/${product_id}/${slug(prod.display_name)}`)
    }
  }

  const replaceProduct = (new_product_id) => {
    axios.get(BE + '/api/webshop/product?id=' + new_product_id).then(res => {
      // Specs to object
      res.data.specs = JSON.parse(res.data.specs || '[]')
      setProduct(res.data)
      setLoading(false)
      setNewProductId(new_product_id);
    })
  }

  const carouselImage = (img) => {
    return <img
      alt={product.display_name}
      data-id={`${img.id}_${img.product_id}.jpg`}
      src={`${imgPath}${img.id}_${img.product_id}.jpg`}
      onError={(e) => {
        e.target.src = srcErr
      }} />
  }

  return (
    <>
      <PurchaseIndicator/>
      {backClicked &&
        <div className="breadcrumbs__top">
          <div className="container__large">
            <span className="breadcrumbs__back">
              <span className="pi pi-chevron-right"></span>
              <TcmsSpan onClick={() => {
                backClicked()
              }}>Terug</TcmsSpan>
            </span>test
            <Breadcrumbs product_id={product_id} />
          </div>
        </div>
      }
      <SidebarCart ref={refSidebarCart} />
      <div className="pdp container__large">
        <div className='wrapper'>
          {
            loading ?
              <div className="spinner-wrapper">
              </div>
              :
              <>
                <div className="p-grid main-prod-container">
                  

                  {/* CENTRE */}
                  <div className="p-col-12 p-col-align-center">
                    <h1 className="pdp_title">{product.display_name}</h1>
                  </div>


                  {/* LEFT SIDE */}
                  <div className="p-col-12 p-md-7 pdp__left">

                    {/*FAVORITE*/}
                    <div className="p-col-12 pdp__favorite">
                      <Button
                        className={classNames("btn butt", context.favorites.includes(product.id) && 'active')}
                        label=""
                        onClick={() => {
                          heartClicked()
                        }} />



                    </div>

                    {/*RIBBONS*/}
                    <div className="pdp__ribbons">
                      {getRibbons(product.ribbon_ids, context.meta.ribbons).map(ribbon =>
                        <>
                         
                          <div className="product-item__ribbon" key={ribbon}>
                            {ribbonIcons[ribbon] ?
                              <TcmsImg alt={ribbon} title={'ribbon_tooltip_'+getRibbonId(ribbon)} className="ribbon_icon" src={ribbonIcons[ribbon]}></TcmsImg>
                              :
                              undefined
                            }
                          </div>
                        </>
                        )
                      }
                    </div>
                    {product.image360 ?
                      <iframe className='360-image' frameBorder={0} style={{ height: '630px', width: '100%' }} src={product.image360}></iframe>
                      :
                      <div className="carousel-container">
                        <div className="main-image">
                          {carouselImage(product.images[selectedImage])}
                        </div>
                        {product.images.length > 1 &&
                          <div className="thumbnails">
                            {product.images.map((img, index) => (
                              <img
                                key={index}
                                alt={product.display_name}
                                data-id={`${img.id}_${img.product_id}.jpg`}
                                src={`${imgPath}${img.id}_${img.product_id}.jpg`}
                                className={`thumbnail ${selectedImage === index ? 'selected' : ''}`}
                                onClick={() => setSelectedImage(index)}
                              />
                            ))}
                          </div>
                        }
                      </div>
                    }

                    <div className='description-container'>
                        <div className="p-grid">
                          <div className="p-col-12">
                            <div className={`accordion ${isOpen ? 'open' : ''}`}>
                              <div className="accordion-header" onClick={toggleAccordion}>
                                <strong>Productinformatie</strong>
                                <span className="accordion-icon">
                                  {isOpen ? (
                                    <span className="pi pi-chevron-up"></span>
                                  ) : (
                                    <span className="pi pi-chevron-down"></span>
                                  )}
                                </span>
                              </div>
                              <div className={`accordion-body ${isOpen ? 'show' : ''}`}>
                                <div className="description" dangerouslySetInnerHTML={{ __html: product.description }}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>

                  {/* RIGHT SIDE */}
                  <div className="p-col-12 p-md-5 pdp-right">
                    {/*SPECS*/}
                    {product.specs.length > 0 &&
                      <div className="specs-container">
                        <h3 className="specs-heading"><Tcms>Productspecificaties</Tcms></h3>
                        {
                          product.specs.map((spec, i) =>
                            <div key={i} className="specs">
                              <span className="name">{spec.name}</span>
                              <span className="value">{spec.spec}</span>
                            </div>)
                        }
                        <hr className="ruler" />
                      </div>
                    }

                    <div className="product-actions">
                      {/*STATUS*/}
                      <div className="status-container">
                        <div className={classNames('status', availability().className)}>
                          <Tcms>{capitalizeFirstLetter(availability().label)}</Tcms>
                        </div>
                        {product.stock.limited_edition ?
                            <div className={classNames('status', limitededition().className)}>
                              <Tcms>{capitalizeFirstLetter(limitededition().label)}</Tcms>
                            </div>
                          :
                          null
                        }
                      </div>
                      <div className='price-qty-container'>
                        <div className="pdp__submitquantity">
                          {/*SPINNER*/}
                          <div className="quantity-button">
                            <InputNumber disabled={no.max === 0 || (!variantSelected && product.brothers_and_sisters.length > 1)}
                              className="spinner"
                              value={no.count}
                              min={no.min}
                              max={no.max}
                              showButtons
                              buttonLayout="horizontal"
                              onValueChange={(e) => {
                                setOrderCount(e.value)
                              }}
                              step={1}
                              decrementButtonClassName="arrow"
                              incrementButtonClassName="arrow"
                              incrementButtonIcon="pi pi-plus"
                              decrementButtonIcon="pi pi-minus"
                              mode="decimal" />
                          </div>
                          {/*PRICE*/}
                          {context.meta.no_price === 0 &&
                            <div className="price-wrapper">
                              <div className="coins-img">
                                <TcmsImg alt=""
                                  src="/media/icons/coins-var.svg" />
                              </div>
                              <div >
                                <div className="price pdp__price-amount">
                                  <span className="eco">
                                  </span> {product.price_in_eco}&nbsp;
                                  <Tcms>ecos</Tcms></div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>

                     

                      <div className="pdp__right-inner">
                        {/*VARIANT*/}
                        {product.brothers_and_sisters.length > 1 &&
                          <div className="variant-container">
                            <h4><Tcms>Variant</Tcms></h4>
                            {context.editMode &&
                              <Tcms>selecteer een variant</Tcms>
                            }
                            <Dropdown
                              className="variant-select"
                              placeholder={!variantSelected ? Tcms('selecteer een variant') : product.display_name}
                              value={null}
                              optionLabel="display_name"
                              optionValue="id"
                              optionDisabled={(option) => option.price_in_eco > balance}
                              options={product.brothers_and_sisters}
                              onChange={(e) => {
                                onSelectChange(e.value)
                                setVariantSelected(true)
                              }}

                            />
                          </div>}
                      </div>
                      {/*SUBMIT*/}
                      <div className="w-100 submit-container pdp__submit">
                        <button
                          disabled={no.max === 0 || (!variantSelected && product.brothers_and_sisters.length > 1)}
                          style={{ cursor: 'pointer' }}
                          className="p-button p-component btn butt tcms-edit"
                          onClick={() => {
                            new_product_id ? addToCartClicked(new_product_id, orderCount) : addToCartClicked((product_id ? product_id : filterContext.activeProductId), orderCount)
                          }}>
                          <Tcms>Toevoegen aan winkelmand</Tcms>
                        </button>
                        {balance > 0 && (product.brothers_and_sisters.length > 1) && (balance < product.price_in_eco) &&
                          <div className="p-message p-component p-message-error p-message-enter-done">
                            <div className="p-message-wrapper p-justify-center">
                              <span className="p-message-summary"></span>
                              <span className="p-message-detail"><TcmsWYSIWYG>select_other_variant</TcmsWYSIWYG></span>
                            </div>
                          </div>
                        }
                        {!balance && product.brothers_and_sisters.length > 1 &&
                          <div className="p-message p-component p-message-error p-message-enter-done">
                            <div className="p-message-wrapper p-justify-center">
                              <span className="p-message-summary"></span>
                              <span className="p-message-detail"><TcmsWYSIWYG>too_few_ecos</TcmsWYSIWYG></span>
                            </div>
                          </div>
                        }
                        {balance < product.price_in_eco && product.brothers_and_sisters.length === 1
                          &&
                          <div className="p-message p-component p-message-error p-message-enter-done">
                            <div className="p-message-wrapper p-justify-center">
                              <span className="p-message-summary"></span>
                              <span className="p-message-detail"><TcmsWYSIWYG>too_few_ecos</TcmsWYSIWYG></span>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </>
          }

         
        </div>
      </div>

      <div className='related-products-container'>
        <div className='container__large'>
          <h3><TcmsSpan>Relatedproducts</TcmsSpan></h3>
          <div className="p-grid">
            {relatedProducts.map((relatedProduct, index) => (
              <Product
                key={index}
                originalIndex={relatedProduct.originalIndex}
                id={relatedProduct.id}
                display_name={relatedProduct.display_name}
                cat_name={relatedProduct.cat_name}
                category_id={relatedProduct.category_id}
                price_in_eco={relatedProduct.price_in_eco}
                stock={relatedProduct.stock}
                image_name={relatedProduct.image_name}
                ribbon_ids={relatedProduct.ribbon_ids}
                ambiance_images={relatedProduct.ambiance_images}
                featured={relatedProduct.featured}
                col_xl={3}
                productClicked={() => {
                  history.push(`/${context.webshopSlug}/product/${relatedProduct.id}/${slug(relatedProduct.display_name)}`)
                }}
              />
            ))}
          </div>

        </div>
      </div>
    </>
  )
}


export default Pdp
