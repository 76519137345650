import React, { forwardRef, useImperativeHandle, useState, useContext, useRef } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from "primereact/dialog";
import axios from 'axios'
import Tcms from "../../tcms/Tcms";
import { TcmsContext } from '../../contexts/TcmsContext';
import TcmsInputText from "../../tcms/Tags/TcmsInputText";
import TcmsButton from '../../tcms/Tags/TcmsButton';
import { Messages } from 'primereact/messages';
import TcmsWYSIWYG from '../../tcms/Tags/TcmsWYSIWYG';

const DialogForgotPassword = forwardRef((props, ref) => {

  const context = useContext(TcmsContext)

  const [visible, setVisible] = useState()
  const [user, setUser] = useState(false);
  const [error, setError] = useState(false);

  const messages = useRef(null);

  const submit = () => {
    axios.post(process.env.REACT_APP_MTG_BACKEND + '/api/webshop/send-forgot-password-mail', { user: user }).then(
      (response) => {
        messages.current.show({
          severity: 'success',
          detail: 'Je hebt een mail ontvangen met verdere instructies',
          life: 30000,
        });
      }
    ).catch((err) => {
      messages.current.show({
        severity: 'warn',
        detail: 'Gebruiker niet herkend',
        life: 30000,
      });
    })
  };

  useImperativeHandle(ref, () => ({
    open() {
      setVisible(true)
    }
  }));

  return <Dialog
    header={<Tcms>Forgot Password</Tcms>}
    visible={visible}
    style={{ width: '60em' }}
    position="top"
    modal={true}
    onHide={() => {
      setVisible(false)
    }}>

    <div className="p-grid">
      <div className="p-col-12 page-wrapper" style={{
        minHeight: '100%',
      }}>
        <div className="login-card-wrapper p-flex p-justify-center" style={{
          paddingLeft: '0px',
          maxWidth: '100%',
          width: '100%',
        }}>
          <div className='wrapper'>
            <TcmsWYSIWYG>fg_intro</TcmsWYSIWYG>
            <div className="p-inputgroup">
              <div className="p-inputgroup-addon">
                <i className="pi pi-user"></i>
              </div>
              <TcmsInputText
                placeholder={'fg_user_email'}
                onChange={(e) => {
                  setUser(e.target.value)
                }}
                style={{ width: '100%' }} />
            </div>
            <div className="p-inputgroup">
              <TcmsButton className="btn-login" label={`${context.languageCode === 'nl_nl' ? 'E-mail versturen' : 'Send email'}`}
                onClick={() => submit()} />
            </div>
            <Messages ref={messages} className="p-col-12 text-center" />
          </div>
        </div>
      </div>
    </div>

    <div className="p-grid">
      <div className="p-col">
        <Button style={{ width: '10em' }}
          label="Annuleer"
          onClick={() => {
            setVisible(false)
          }} />
      </div>
    </div>
  </Dialog>
})

export default DialogForgotPassword
