import TcmsH1 from "../../../tcms/Tags/TcmsH1";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {TcmsContext} from "../../../contexts/TcmsContext";
import Tcms from "../../../tcms/Tcms";
import Product from "../../../components/webshop/product/Product";
import slug from "../../../services/slug";
import {FilterContext} from "../../../contexts/FilterContext";
import {useHistory} from "react-router-dom";

const BE = process.env.REACT_APP_MTG_BACKEND

const Top3Producten = ({replaceWithPdp}) => {
    const history = useHistory()
    const filterContext = useContext(FilterContext)
    const [products, setProducts] = useState([])
    let context = useContext(TcmsContext)

    useEffect(() => {
        axios.get(BE + '/api/webshop/widgets/top-3-producten').then(res => {
            setProducts(res.data.top3products)
        });
    }, [])

    return <>
        <div className="p-grid">
            <div className="p-col-12 p-mt-6">
                <TcmsH1 className="title_poll">De top 3 gekozen producten zijn:</TcmsH1>

            </div>
        </div>
        <div className="p-grid">
        {products.length > 0 ? products.map((product, index) => (
                <Product
                    optionalRanking={index + 1}
                    originalIndex={29}
                    id={product.id}
                    display_name={product.display_name}
                    cat_name={product.cat_name}
                    category_id={product.category_id}
                    price_in_eco={product.price_in_eco}
                    stock={product.stock}
                    image_name={product.image_name}
                    ribbon_ids={[25]}
                    ambiance_images={product.ambiance_images}
                    productClicked={() => {

                        filterContext.setActiveSubCats([product.category_id])

                        if(replaceWithPdp){
                            // used in virtual market
                            replaceWithPdp(product.id)
                        } else {
                            // Normal web page
                            history.push(`/${context.webshopSlug}/product/${product.id}/${slug(product.display_name)}`)
                        }

                    }}/>
        )) : <div className='p-d-flex p-justify-center'><Tcms>Producten laden, dit kan even duren...</Tcms></div>}
        <div className='p-grid p-m-auto' style={{width: '100%'}}>
            </div>
        </div>
    </>
}

export default Top3Producten
