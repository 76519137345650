import React, {useContext, useRef, useState} from 'react'
import {TcmsContext} from "../../../contexts/TcmsContext";
import {Card} from "primereact/card";
import Tcms from "../../../tcms/Tcms";
import axios from "axios";
import {OverlayPanel} from "primereact/overlaypanel";
import TcmsButton from "../../../tcms/Tags/TcmsButton";
import TcmsLabel from "../../../tcms/Tags/TcmsLabel";
import TcmsSpan from '../../../tcms/Tags/TcmsSpan';

export default function Cart(props) {
  const op = useRef()
  let context = useContext(TcmsContext)

  // This is so we can work with only 1 overlay
  let [overlayContent, setOverlayContent] = useState()

  const outOfStockOverlayOkClicked = (action,item) => {

    switch (action) {
      case 'remove':
        deleteClicked(item.id)
        break;
      case 'adjust':
        break;
      default:
    }
  }

  /**
   * Compose red text after product display_name when not (enough) available
   * !! This routine is 'tweakable' !!
   */
  const statusHeader = (item) => {

    // When admin and status not empty !! tweak !!
    let {status, soldout, qty_left} = item.avail

    if (status === 'ok') return null

    switch (status) {
      case 'ok':
        return null
      case 'not_available':
        return <span><Tcms>Dit product is niet meer beschikbaar</Tcms></span>
      case 'not_enough_stock':
        if (soldout) {
          return <span><Tcms>Nog maar</Tcms> {qty_left} <Tcms>beschikbaar</Tcms></span>
        } else {
          return <span><Tcms>Op dit moment maar</Tcms> {qty_left} <Tcms>beschikbaar.</Tcms></span>
        }
      case 'out_of_stock':
        if (soldout) {
          return <span><Tcms>Helaas is dit product uitverkocht</Tcms></span>
        } else {
          return <span><Tcms>Op dit moment is dit product niet op voorraad</Tcms></span>
        }

      default:
      //
    }
  }

  /**
   * Compose Overlay message when product not (enough) available
   * * !! This routine is 'tweakable' !!
   */
  const handleOutOfStockOverlay = (item, e) => {


    // When admin and status not empty !! tweak !!
    let {status, soldout, qty_left} = item.avail

    let content
    switch (status) {
      case 'not_available':
        content =
          <React.Fragment>
            <p>
              <Tcms>Dit product is niet meer beschikbaar in deze webshop</Tcms>
            </p>
            <p>
              <input type="radio" name="options" value="remove" defaultChecked style={{display: 'none'}}/>
              <Tcms>Klik OK om dit product te verwijderen</Tcms>
            </p>

          </React.Fragment>
        break
      case 'not_enough_stock':
        if (soldout) {
          content =
            <React.Fragment>
              <p>
                <Tcms>Nog maar</Tcms> {qty_left} <Tcms>beschikbaar</Tcms>
              </p>
              <p>
                <Tcms>Wat wil je doen?</Tcms>
              </p>
              <p>
                <input id="radio1" type="radio" name="options" value="adjust" defaultChecked/>
                <TcmsLabel htmlFor="radio1">Winkelmand aanpassen</TcmsLabel>
              </p>
              <p>
                <input id="radio2" type="radio" name="options" value="adjust"/>
                <TcmsLabel htmlFor="radio2">Dit product niet bestellen</TcmsLabel>
              </p>
            </React.Fragment>
        } else {
          content =
            <React.Fragment>
              <p>
                <Tcms>Op dit moment maar</Tcms> {qty_left} <Tcms>beschikbaar.</Tcms>
              </p>
              <p>
                <Tcms>Wat wil je doen?</Tcms>
              </p>
              <p>
                <input id="radio1" type="radio" name="options" value="adjust" defaultChecked/>
                <TcmsLabel htmlFor="radio1">Winkelmand aanpassen</TcmsLabel>
              </p>
              <p>
                <input id="radio2" type="radio" name="options" value="wait"/>
                <TcmsLabel htmlFor="radio2">Wachten</TcmsLabel>
              </p>
              <p>
                <input id="radio3" type="radio" name="options" value="remove"/>
                <TcmsLabel htmlFor="radio3">Dit product niet bestellen</TcmsLabel>
              </p>
            </React.Fragment>
        }
        break;
      case 'out_of_stock':
        if (soldout) {
          content =
            <React.Fragment>

              <p>
                <Tcms>Helaas is dit product uitverkocht</Tcms>
              </p>

              <p>
                <input type="radio" name="options" value="remove" defaultChecked style={{display: 'none'}}/>
                <Tcms>Klik OK om dit product te verwijderen</Tcms>
              </p>

            </React.Fragment>


        } else {
          content =
            <React.Fragment>
              <p>
                <Tcms>Op dit moment is dit product niet op voorraad</Tcms>
              </p>
              <p>
                <Tcms>Wat wil je doen?</Tcms>
              </p>
              <p>
                <input id="radio2" type="radio" name="options" value="wait" defaultChecked/>
                <TcmsLabel htmlFor="radio2">Wachten</TcmsLabel>
              </p>
              <p>
                <input id="radio3" type="radio" name="options" value="remove"/>
                <TcmsLabel htmlFor="radio3">Dit product niet bestellen</TcmsLabel>
              </p>
            </React.Fragment>
        }
        break;
      default:
      //
    }

    setOverlayContent(
      <div>
        {content}
        <div className="lala" style={{display: 'flex', justifyContent: 'space-between'}}>
          <TcmsButton
            label="OK"
            onClick={() => {
              outOfStockOverlayOkClicked(
                document.querySelector('input[name="options"]:checked').value,
                item
              )
              op.current.hide()
            }}
            style={{width: '6rem'}}
          />
          <TcmsButton
            label="Annuleer"
            onClick={() => op.current.hide()}
            className="p-button-warning"
            style={{width: '6rem'}}
          />

        </div>
      </div>
    )
    // Open the panel
    op.current.toggle(e)
  }


  const deleteClicked = (id) => {
    axios.get(process.env.REACT_APP_MTG_BACKEND + '/api/webshop/remove-from-cart', {
      params: {
        id
      }
    }).then(res => {
      // Have to update the jwt_token
      context.setCart(res.data.cart)
      context.renewJwt(res.data.jwt_token)
    })
  }

  let errSrc = '/media/images/placeholders/85x85.png'

  /**
   * * !! This routine is 'tweakable' !!
   */
  const itemTemplate = () => {

    return context.cart.items.map(item => {

      let src = `${process.env.REACT_APP_MTG_BACKEND}/storage/img/odoo-producten/85x85/${item.image_name}.jpg`

      // When admin and status not empty !! tweak !!
      // let {avail} = context.tweak
      let {status} = item.avail

      return (
        <Card key={item.id} className={item.avail.status !== 'ok' ? 'out-of-stock' : ''}>

          <div className="order-line">
            <div className="image" style={{width: '85px'}}>
              <img src={src} alt={item.display_name} onError={e => e.target.src = errSrc}/>
            </div>
            <div className="name_description" style={{width: '100%'}}>
              <h4>
                {item.display_name}
              </h4>
              <span className="cart__alert">{statusHeader(item)}</span>
              <div className="cart__content-bottom">
                <p className="count"><Tcms>Aantal:</Tcms> {item.count}</p>
                {context.meta.no_price === 0 &&
                  <p className="amount">| &nbsp; <span className="eco"><img alt="" src="/media/icons/coins.svg"/></span> {item.count * item.ecos}
                    &nbsp;<Tcms>{item.count * item.ecos > 1 ? 'punten' : 'punt'}</Tcms>
                  </p>
                }
              </div>
            </div>

            {/* {status !== 'ok' && <div className="cart__question question" style={{width: '85px'}}>
              <TcmsButton
                title="Klik hier voor meer opties"
                onClick={(e) => {
                  handleOutOfStockOverlay(item, e)
                }}
                icon="pi pi-question"
                className="p-button-danger p-button-rounded p-button-outlined"
              />
            </div>} */}

            <div className="delete cart__delete" style={{width: '85px'}}>
              {item.ecos !== 0 &&<TcmsButton
                title="Verwijder uit winkelmand."
                onClick={() => deleteClicked(item.id)}
                icon="pi pi-times"
                className="p-button-danger p-button-rounded p-button-outlined"
              />}

            </div>
          </div>
        </Card>

      )
    });
  }


  // The main return
  return (
    <React.Fragment>
      <OverlayPanel className="out-of-stock-overlay"
                    ref={op}
                    dismissable={context.isAdmin ? false : true}
                    appendTo={document.getElementById('root')}>
        {overlayContent}
      </OverlayPanel>
      <div className="cart">
        {context.cart.items.length == 0 &&
          <TcmsSpan>no_products_in_cart</TcmsSpan>
        }
        {itemTemplate()}
      </div>
    </React.Fragment>
  )

}

