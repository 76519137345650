import React, { useContext, useRef, useState } from "react";
import Menu from "../../../components/webshop/menu/Menu";
import Background from "../../../components/webshop/background/Background";
import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";
import TcmsImg from "../../../tcms/Tags/TcmsImg";
import { TcmsContext } from "../../../contexts/TcmsContext";
import TcmsInputText from "../../../tcms/Tags/TcmsInputText";
import TcmsLabel from "../../../tcms/Tags/TcmsLabel";
import TcmsDropdown from "../../../tcms/Tags/TcmsDropdown";
import TcmsButton from "../../../tcms/Tags/TcmsButton";
import { Messages } from 'primereact/messages';
import axios from "axios";

export default function GivePoints(props) {

  const context = useContext(TcmsContext)
  const [user, setUser] = useState();
  const [points, setPoints] = useState(false);
  const [code, setCode] = useState();
  const [pointsSent, setPointsSent] = useState(false);
  const [canGive, setCanGive] = useState(true);

  const orders = context.orders;
  const customPoints = context.meta.allow_custom_gift_points;

  const balance = context.getBalance();
  const messages = useRef(null);

  const onPointsChange = (e) => {
    setPoints(e.value);
  }

  const selectPoints = [];

  const x = balance / context.meta.eco_threshold;

  if (!customPoints) {
    if (orders.length > 0 ) {
      setCanGive(false);
    } else {
      selectPoints.push({name: balance, id: balance})
    }
  } else {
    for (let i = 1; i <= x; i++) {
        let amount = i * context.meta.eco_threshold
        selectPoints.push({name: amount, id: amount})
    };
  }


  const givePoints = () => {
    axios.post(process.env.REACT_APP_MTG_BACKEND + '/api/webshop/give-points', {currentuser:context.jwt.usr.uid,user:user, points:points.name}).then(
      (response) => {
        setPointsSent(true);
        setUser();
        messages.current.show({
          severity: 'success',
          detail: 'Je hebt een mail ontvangen met verdere instructies',
          life: 30000,
      });
      }
    ).catch((err) => {
      messages.current.show({
          severity: 'warn',
          detail: 'Gebruiker niet herkend',
          life: 30000,
      });
    })
  };

  const activateGiftPoints = () => {
    axios.post(process.env.REACT_APP_MTG_BACKEND + '/api/webshop/activate-gift-points', {currentuser:context.jwt.usr.uid,code:code}).then(
      (response) => {
        setPointsSent(true);
        context.renewJwt(response.data.jwt_token)
        messages.current.show({
          severity: 'success',
          detail: 'De punten zijn geschonken!',
          life: 30000,
      });
      }
    ).catch((err) => {
      messages.current.show({
          severity: 'warn',
          detail: 'Code niet herkend of is al gebruikt.',
          life: 30000,
      });
    })
  };

  return (

    <React.Fragment>

      <Menu/>
      <div className='page-custom page-givepoints'>
        <Background/> 
        {canGive ?
        <div className="container__large content-page main-view p-mt-6">
          <div className="p-mb-3 p-mt-3">
            <TcmsWYSIWYG>give_points_top</TcmsWYSIWYG>
          </div>
          <div className="p-flex p-justify-center">
            <TcmsImg className="usp-icon" src="/media/icons/icon-give-points.svg"/>
          </div>
          <div className="p-mt-6 p-text-center">
            <TcmsWYSIWYG>give_points_intro</TcmsWYSIWYG>
          </div>
          <div className="p-mt-6 p-flex p-justify-center">
            {pointsSent 
            ?
            <div className="form-wrapper">
              <div className="p-mt-4">
                <TcmsLabel>Code</TcmsLabel>
                <div className="p-inputgroup">
                  <div className="p-inputgroup-addon">
                    <i className="pi pi-lock"></i>
                  </div>
                  <TcmsInputText
                    placeholder={`${context.languageCode === 'nl_nl' ? 'Vul hier de code in die je in je mail hebt ontvangen' : 'Code'}`}
                    value={code}
                    onChange={(e) => {
                      setCode(e.target.value)
                    }}
                    style={{width: '100%'}}/>
                </div>
              </div>
              <div className="p-mt-4">
                <TcmsButton className="btn-login" label={`${context.languageCode === 'nl_nl' ? 'Activeer code' : 'Activate code'}`}
                              onClick={() => activateGiftPoints()}/>
              </div>
            </div>
            :
            <div className="form-wrapper">
              <div className="p-mt-4">
                <TcmsLabel>E-mailadres</TcmsLabel>
                <div className="p-inputgroup">
                  <div className="p-inputgroup-addon">
                    <i className="pi pi-user"></i>
                  </div>
                  <TcmsInputText
                    placeholder={`${context.languageCode === 'nl_nl' ? 'Vul hier het werk e-mailadres in van je collega' : 'Email'}`}
                    value={user}
                    onChange={(e) => {
                      setUser(e.target.value)
                    }}
                    style={{width: '100%'}}/>
                </div>
              </div>
              <div className="p-mt-4">
                <TcmsLabel>Hoeveel punten wil je schenken?</TcmsLabel>
                <div className="p-inputgroup">
                  <TcmsDropdown name="voucher_amount"
                      value={points}
                      options={selectPoints}
                      onChange={onPointsChange}
                      optionLabel="name"
                      placeholder="Aantal punten"/>
                </div>
              </div>
              <div className="p-mt-4">
                <TcmsButton className="btn-login" label={`${context.languageCode === 'nl_nl' ? 'Schenk mijn punten' : 'Gift my points'}`}
                              onClick={() => givePoints()}/>
              </div>
            </div>

            }
          </div>
          <Messages ref={messages} className="p-col-12 text-center"/>
        </div>
        :
          <div className="container__large content-page main-view p-mt-6">
            <div className="p-flex p-justify-center">
              <TcmsImg className="usp-icon" src="/media/icons/icon-give-points.svg"/>
            </div>
            <div className="p-mt-6 p-text-center">
              <TcmsWYSIWYG>cant_give_points</TcmsWYSIWYG>
            </div>
          </div>
        }
      </div>
    </React.Fragment>
  )

}



